import React, { useState } from 'react'
import content from '../content.yaml'
import './NavBar.css'

interface NavBarProps {
  onSelect: (section: string) => void
}

const NavBar: React.FC<NavBarProps> = ({ onSelect }) => {
  const { book } = content
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const handleSelect = (section: string) => {
    onSelect(section)
    setMenuOpen(false)
  }

  return (
    <nav className="NavBar">
      <button className="Menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={menuOpen ? 'show' : ''}>
        <li>
          <button className="nav-link" onClick={() => handleSelect('blurb')}>
            About the Book
          </button>
        </li>
        <li>
          <button className="nav-link" onClick={() => handleSelect('about')}>
            About the Author
          </button>
        </li>
        <li>
          <button className="nav-link" onClick={() => handleSelect('prologue')}>
            Read the Prologue
          </button>
        </li>
        <li>
          <a
            className="nav-link"
            href={book.amazonLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => setMenuOpen(false)}
          >
            Buy on Amazon
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            href={book.barnesAndNobleLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => setMenuOpen(false)}
          >
            Buy on Barnes & Noble
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default NavBar
