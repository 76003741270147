import NiceModal from '@ebay/nice-modal-react'
import Handlebars from 'handlebars'
import React, { useRef } from 'react'
import './App.css'
import CoverSection from './components/CoverSection'
import NavBar from './components/NavBar'
import TextModal from './components/TextModal'
import content from './content.yaml'
import prologue from './prologue.txt'

const App: React.FC = () => {
  const { author, book, copyright } = content

  // Refs for each section
  const coverRef = useRef<HTMLDivElement | null>(null)
  const blurbRef = useRef<HTMLDivElement | null>(null)
  const buyRef = useRef<HTMLDivElement | null>(null)
  const aboutRef = useRef<HTMLDivElement | null>(null)

  const showPrologueModal = () => {
    NiceModal.show(TextModal, {
      title: 'PROLOGUE',
      content: prologue,
      links: [
        {
          title: 'Buy on Amazon',
          url: book.amazonLink,
        },
        {
          title: 'Buy on Barnes & Noble',
          url: book.barnesAndNobleLink,
        },
      ],
    })
  }

  const scrollToSection = (section: string) => {
    if (section === 'prologue') {
      showPrologueModal()
      return
    }

    const refs: Record<string, React.RefObject<HTMLDivElement>> = {
      cover: coverRef,
      blurb: blurbRef,
      buy: buyRef,
      about: aboutRef,
    }
    refs[section]?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const copyrightTemplate = Handlebars.compile(copyright.text)

  return (
    <div className="App" ref={coverRef}>
      <NavBar onSelect={scrollToSection} />

      {/* Cover Section */}
      <div>
        <CoverSection
          coverImage={book.cover_regular}
          coverImageHd={book.cover_hidpi}
          title={book.title}
          tagline={book.tagline}
          author={author.name}
          onReadBlurb={() => scrollToSection('blurb')}
        />
      </div>

      {/* Blurb Section */}
      <section ref={blurbRef} className="Blurb-section">
        <h2>About the Book</h2>
        <div
          className="Book-blurb"
          dangerouslySetInnerHTML={{
            __html: book.blurb
              .split('\n')
              .map((line: string) => `<p>${line}</p>`)
              .join('\n'),
          }}
        />
        <button className="link" onClick={showPrologueModal}>
          Read the prologue
        </button>
      </section>

      {/* About the Author Section */}
      <section ref={aboutRef} className="Author-section">
        <h2>About the Author</h2>
        <p className="Author-bio">{author.bio}</p>
      </section>

      {/* Copyright Section */}
      <footer className="Copyright-section">
        <p>{copyrightTemplate({ year: new Date().getFullYear() })}</p>
      </footer>
    </div>
  )
}

export default App
